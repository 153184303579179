//-------------------------------------------------------inputs and selects
%form_field_default {
	display: block;
	width: 100%;
	height: $input_height;
	padding: $input_offset;
	font-size: $input_font_size;
	font-family: $font_1;
	color: $input_text_color;
	// box-shadow: inset 0 0 0 1px $input_border_color;
	border:none;
	border-radius: $input_radius;

	&::-webkit-input-placeholder {
		font-style: italic;
		color: $input_placeholder_color;
	}

	&::-moz-placeholder {
		font-style: italic;
		color: $input_placeholder_color;
	}

	&:-ms-input-placeholder {
		font-style: italic;
		color: $input_placeholder_color;
	}

}

%form_field_default_hover {
}

%form_field_default_active {
	box-shadow: inset 0 0 0 1px $blue;
}

.form_cell {
	&.hidden_field {
		display: none;
	}

	&.form_cell_v1_mod {
	}

	&.form_cell_v2_mod {
		@extend %global_flex_block_column_nowrap_flex-start_stretch;
		position: relative;
	}
}

.form_cell_title {

	&.hline_hide_mod {
		display: none;
	}

	&.form_v1_mod {
	}

	&.form_v2_mod {

		z-index: 1;

		// position: absolute;
		// top: calc(100% + .4rem);
		// left: 0;
		order: 2;

		color: $black;
		font-size: 1.6rem;
		font-weight: 400;
		opacity: .5;

		pointer-events: none;

		@include bp($point_4, min) {
			transition: transform .3s ease;
			will-change: transform;
		}

		@include bp($point_4 - 1) {
			transition: opacity .3s ease;
			will-change: opacity;
		}

		&.active_mod {
			@include bp($point_4, min) {
				transform: translateY(-4.2rem);
			}

			@include bp($point_4 - 1) {
				opacity: 0;
			}
		}
	}

}

.form_field_wrap {
	position: relative;

	&.form_v1_mod {
	}

	&.form_v2_mod {
		@include bp($point_4 - 1) {
			transition: border-color .3s ease;
			border-bottom: 1px solid transparent;
		}

		&.active_mod {
			border-color: rgba(#212b36, .5);
		}
	}

}

.form_field {
	&.default_mod {
		@extend %form_field_default !optional;

		&:focus {
			@extend %form_field_default_active !optional;
		}

		&:hover {
			@extend %form_field_default_hover !optional;
		}
	}

	&.hero_mod {
		display: block;
		width: 100%;
		height: 4rem;
		padding: 0 2rem 0 0;
		font-size: 2.5rem;
		font-weight: 700;
		font-family: $font_1;
		color: $gray;
		box-shadow: none;
		border:none;
		border-radius: 0;

		&::-webkit-input-placeholder {
			color: $input_placeholder_color;
		}

		&::-moz-placeholder {
			color: $input_placeholder_color;
		}

		&:-ms-input-placeholder {
			color: $input_placeholder_color;
		}
	}

	&.icon_mod {
		padding-left: 5rem;
	}

	&.textarea_mod_1 {
	}

}

//---------------------------------------------inputs and selects###

//---------------------------------------------checkboxes and radio-buttons

// copy/paste from helpers/components/forms/_form.scss

//---------------------------------------------checkboxes and radio-buttons###