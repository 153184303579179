//---------------------------------------------icons
.icon_w {
	display: block;

	&.header_link_mod {
		width: (2.6em/1.6);
		height: (2em/1.6);
		margin-right: (1.6em/1.6);
	}
}

.icon {
	&.size_mod {
		display: block;
		width: 100%;
		height: 100%;
	}
}