//---------------------------------------------sections
.section {
	position: relative;

	width: 100%;

	@include bp($point_4, min) {
		padding: 8em $gl_indent;
	}

	@include bp($point_4 - 1) {
		padding: 4em $gl_indent;
	}

	font-size: 1rem;

	&.no_offset_mod {
		padding: 0 $gl_indent;
	}

	&.lg_offset_bottom {
		padding-bottom: 32em;
	}
}

.section_in {
	max-width: $page_width;
	width: 100%;
	margin: 0 auto;

	&.v2_mod {
		max-width: 120rem;
	}

	&.hero_mod {
		@extend %global_flex_block_row_wrap_flex-start_center;

		height: 100vh;
		min-height: 90em;
	}

	&.footer_mod {
		@extend %global_flex_block_column_nowrap_flex-start_stretch;
		flex-grow: 1;
		max-width: 122.4rem;
	}

	&.flex_mod {
		@extend %global_flex_block_row_wrap_flex-start;
	}

	&.flex_v2_mod {
		@extend %global_flex_block_row_wrap_flex-start_stretch;
		height: 100%;
	}

}

.section_col {
	@include bp($point_1 + 1, min) {
		width: 50%;
	}

	@include bp($point_1) {
		width: 100%;

		&:first-child {
			margin-bottom: 8em;
		}
	}
}

.section_title {
	width: 100%;

	color: $black;
	font-family: $circular;
	font-weight: 900;
	line-height: (6/5);

	@include bp($point_4, min) {
		font-size: 5em;
	}

	@include bp($point_4 - 1) {
		font-size: 4em;
	}

	&.offset_mod {
		margin-bottom: (4.4em/5);
	}

	&.offset_2_mod {
		margin-bottom: (7.6em/5);
	}

	&.offset_3_mod {
		margin-bottom: (12.4em/5);
	}

	&.limit_mod {
		max-width: (67.4em/5);
	}

	&.decor_mod {
		position: relative;
		padding-left: (3.2em/5);

		@include bp(1300px) {
			padding-left: 6rem;
		}
	}

	&.slider_mod {
		width: 56.2%;
		padding-left: 7.8rem;
		margin-left: auto;

		@include bp($point_3) {
			width: 100%;
			padding: 0;
			margin-bottom: 10vw;
		}
	}

	span {
		color: $blue;
	}
}

.section_descr {
	color: $gray;
	font-size: 1.8em;
	font-weight: 400;

	p {
		margin-bottom: (3em/1.8);

		&:last-child {
			margin-bottom: 0;
		}
	}

	strong {
		font-weight: 600;
		font-style: italic;
	}

	&.side_offset_mod {
		@include bp($point_4, min) {
			padding-left: (5em/1.8);
		}
	}
}