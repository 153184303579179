//buttons

.btn_base {
	@extend %btn_size_1;

	&:not(.gray_mod) {
		@extend %red_btn_1;
	}

	&.gray_mod {
		@extend %gray_btn_1;
	}

	&.full_mod {
		width: 100%;
	}

	.advisor_item & {
		width: 100%;
		min-width: 0;
	}

	@include bp($point_4 - 1) {
		.hero_form_w & {
			width: 100%;
		}
	}

}

.btn_v2 {
	@extend %btn_size_2;
	@extend %blue_btn_1;
}

// section decor

.section_decor {
	position: absolute;

	&.pos_1_mod {
		top: 50%;
		right: 0;

		width: 41em;
		height: 29.8em;
		margin-right: -20.6em;
		margin-top: -13.6em;

		@include bp($point_4 - 1) {
			width: 50vw;
			height: auto;
			margin-right: -25vw;
		}
	}

	&.pos_2_mod {
		left: 0;

		width: 48.8em;
		height: 30.8em;
		margin: 0 0 0 -24em;

		@include bp($point_1 + 1, min) {
			top: -7.2em;
		}

		@include bp($point_1) {
			top: 10em;
		}

		@include bp($point_4 - 1) {
			display: none;
		}
	}

	&.pos_3_mod {
		top: -12em;
	}

	&.pos_4_mod {
		display: none;

		top: 11em;
		right: 100%;

		width: 119em;
		height: 54.8em;
		margin-right: -11em;

		background-color: $gray_2;

		@include bp($point_1 + 400) {
			display: none;
		}
	}

	&.pos_5_mod {
		top: 0;
		right: 0;

		width: 22.2em;
		height: 22.2em;
		margin: -5.2em -11rem 0 0;

		opacity: .5;
	}
}

.section_decor_img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.hidden_w {
	position: absolute;
	bottom: 0;
	left: 0;

	opacity: 0;
	pointer-events: none;
}