@charset "UTF-8";
.header_in, .header_nav_list, .footer_top, .section_in.flex_mod, .roadmap_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.section_in.flex_v2_mod, .tools_list, .privacy_w {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch; }

.header_col, .header_side_link, .footer_bottom, .section_in.hero_mod {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center; }

.hero_form, .quote_block_w {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-end;
  align-items: flex-end; }

.quote_block, .slick-dots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center; }

.footer_form, .advisors_block, .advisors_list, .title_decor {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.social_list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center; }

.tools_item_in {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.footer, .form_cell.form_cell_v2_mod, .section_in.footer_mod {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch; }

.header:before, .header_logo, .privacy_nav_link:before {
  transition: opacity .3s ease;
  will-change: opacity; }

.header_nav_link, .header_side_link, .why_control {
  transition: color .3s ease;
  will-change: color; }

.more_link:before {
  transition: transform .3s ease;
  will-change: transform; }

.btn_base:not(.gray_mod) {
  font-weight: 700;
  color: #fff;
  background-color: #ff5779;
  transition: .3s ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  cursor: pointer; }
  .btn_base:hover:not(.gray_mod), .btn_base:active:not(.gray_mod), .btn_base:focus:not(.gray_mod) {
    text-decoration: none; }
  .btn_base:hover:not(.gray_mod), .btn_base:active:not(.gray_mod), .btn_base:focus:not(.gray_mod) {
    background: #ff2450; }

.btn_base.gray_mod {
  font-weight: 700;
  color: #b6b6b6;
  background-color: #edf6f7;
  transition: .3s ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  cursor: pointer; }
  .btn_base.gray_mod:hover, .btn_base.gray_mod:active, .btn_base.gray_mod:focus {
    text-decoration: none; }
  .btn_base.gray_mod:hover, .btn_base.gray_mod:active, .btn_base.gray_mod:focus {
    background: #cae4e7; }

.btn_v2 {
  font-weight: 700;
  color: #fff;
  background-color: #14e7fa;
  transition: .3s ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  cursor: pointer; }
  .btn_v2:hover, .btn_v2:active, .btn_v2:focus {
    text-decoration: none; }
  .btn_v2:hover, .btn_v2:active, .btn_v2:focus {
    background: #ff2450; }

.btn_base {
  min-width: 25rem;
  height: 7.7rem;
  padding: 0 1.2rem;
  line-height: 7.7rem;
  font-size: 1.8rem; }

.btn_v2 {
  width: 16.4rem;
  height: 5rem;
  padding: 0 1.2rem;
  line-height: 5rem;
  font-size: 1.6rem; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box; }
  html:before, html:after, body:before, body:after, div:before, div:after, span:before, span:after, object:before, object:after, iframe:before, iframe:after,
  h1:before,
  h1:after, h2:before, h2:after, h3:before, h3:after, h4:before, h4:after, h5:before, h5:after, h6:before, h6:after, p:before, p:after, blockquote:before, blockquote:after, pre:before, pre:after,
  abbr:before,
  abbr:after, address:before, address:after, cite:before, cite:after, code:before, code:after,
  del:before,
  del:after, dfn:before, dfn:after, em:before, em:after, img:before, img:after, ins:before, ins:after, kbd:before, kbd:after, q:before, q:after, samp:before, samp:after,
  small:before,
  small:after, strong:before, strong:after, var:before, var:after,
  b:before,
  b:after, i:before, i:after,
  dl:before,
  dl:after, dt:before, dt:after, dd:before, dd:after, ol:before, ol:after, ul:before, ul:after, li:before, li:after,
  fieldset:before,
  fieldset:after, form:before, form:after, label:before, label:after, legend:before, legend:after,
  article:before,
  article:after, aside:before, aside:after, canvas:before, canvas:after, details:before, details:after, figcaption:before, figcaption:after, figure:before, figure:after,
  footer:before,
  footer:after, header:before, header:after, hgroup:before, hgroup:after, menu:before, menu:after, nav:before, nav:after, section:before, section:after, summary:before, summary:after,
  time:before,
  time:after, mark:before, mark:after, audio:before, audio:after, video:before, video:after, button:before, button:after {
    box-sizing: border-box; }

sub,
sup {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  box-sizing: border-box; }
  sub:before, sub:after,
  sup:before,
  sup:after {
    box-sizing: border-box; }

a:before, a:after {
  box-sizing: border-box; }

table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: none; }

q:before, q:after {
  content: none; }

:focus {
  outline: 0; }

input[type="text"], textarea {
  -webkit-appearance: none; }
  input[type="text"]::-ms-clear, textarea::-ms-clear {
    display: none; }

input,
textarea {
  border-radius: 0;
  box-sizing: border-box; }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    color: transparent; }
  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
    color: transparent; }
  input:focus:-ms-input-placeholder,
  textarea:focus:-ms-input-placeholder {
    color: transparent; }

textarea {
  resize: none; }

ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box;
  word-wrap: break-word; }

@font-face {
  font-family: "Circular";
  src: url("../fonts/CircularStd-Medium.woff2") format("woff2"), url("../fonts/CircularStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Circular";
  src: url("../fonts/CircularStd-Black.woff2") format("woff2"), url("../fonts/CircularStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Avenir";
  src: url("../fonts/AvenirNext-Regular.woff2") format("woff2"), url("../fonts/AvenirNext-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Avenir";
  src: url("../fonts/AvenirNext-Italic.woff2") format("woff2"), url("../fonts/AvenirNext-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Avenir";
  src: url("../fonts/AvenirNext-Medium.woff2") format("woff2"), url("../fonts/AvenirNext-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Avenir";
  src: url("../fonts/AvenirNext-DemiBold.woff2") format("woff2"), url("../fonts/AvenirNext-DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Avenir";
  src: url("../fonts/AvenirNext-DemiBoldItalic.woff2") format("woff2"), url("../fonts/AvenirNext-DemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: "Avenir";
  src: url("../fonts/AvenirNext-Bold.woff2") format("woff2"), url("../fonts/AvenirNext-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

html {
  height: 100%;
  font: 400 calc(100vw / 1920 * 10)/1.33 "Avenir", sans-serif; }
  @media (min-width: 375px) and (max-width: 1600px) {
    html {
      font-size: 10px; } }
  @media screen and (max-width: 374px) {
    html {
      font-size: 9px; } }

body {
  position: relative;
  display: flex;
  height: 100%;
  font-size: 1.6rem;
  color: #000;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff; }
  @supports (display: grid) {
    body {
      display: block; } }
  body.menu_open {
    overflow: hidden; }

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto !important;
  overflow-x: hidden; }

.base {
  width: 100%;
  position: relative;
  padding: 10rem 0 0;
  flex: 1 0 auto; }
  body.index_mod .base {
    padding-top: 0; }

a {
  text-decoration: none;
  color: #4aa3df; }
  a:hover, a:focus {
    text-decoration: underline; }

.elements_list {
  padding: 3rem; }

.btn_base.full_mod {
  width: 100%; }

.advisor_item .btn_base {
  width: 100%;
  min-width: 0; }

@media screen and (max-width: 767px) {
  .hero_form_w .btn_base {
    width: 100%; } }

.section_decor {
  position: absolute; }
  .section_decor.pos_1_mod {
    top: 50%;
    right: 0;
    width: 41em;
    height: 29.8em;
    margin-right: -20.6em;
    margin-top: -13.6em; }
    @media screen and (max-width: 767px) {
      .section_decor.pos_1_mod {
        width: 50vw;
        height: auto;
        margin-right: -25vw; } }
  .section_decor.pos_2_mod {
    left: 0;
    width: 48.8em;
    height: 30.8em;
    margin: 0 0 0 -24em; }
    @media screen and (min-width: 1201px) {
      .section_decor.pos_2_mod {
        top: -7.2em; } }
    @media screen and (max-width: 1200px) {
      .section_decor.pos_2_mod {
        top: 10em; } }
    @media screen and (max-width: 767px) {
      .section_decor.pos_2_mod {
        display: none; } }
  .section_decor.pos_3_mod {
    top: -12em; }
  .section_decor.pos_4_mod {
    display: none;
    top: 11em;
    right: 100%;
    width: 119em;
    height: 54.8em;
    margin-right: -11em;
    background-color: #f9f9f9; }
    @media screen and (max-width: 1600px) {
      .section_decor.pos_4_mod {
        display: none; } }
  .section_decor.pos_5_mod {
    top: 0;
    right: 0;
    width: 22.2em;
    height: 22.2em;
    margin: -5.2em -11rem 0 0;
    opacity: .5; }

.section_decor_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; }

.hidden_w {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1rem;
  will-change: transform;
  z-index: 100; }
  .header:before {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    box-shadow: 0 2px 1em 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    opacity: 0; }
  .header.scroll_mod:before {
    opacity: 1; }
  .header:not(.v2_mod) {
    padding: 0 2rem; }
    @media screen and (min-width: 1201px) {
      .header:not(.v2_mod) {
        height: 10rem; } }
    @media screen and (max-width: 1200px) {
      .header:not(.v2_mod) {
        height: 6rem; } }
  .header.v2_mod {
    height: 7rem;
    border-bottom: 1px solid rgba(34, 90, 89, 0.2); }
    @media screen and (min-width: 1025px) {
      .header.v2_mod {
        padding: 0 8rem; } }
    @media screen and (max-width: 1024px) {
      .header.v2_mod {
        padding: 0 2rem; } }

.header_in {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 135.4rem;
  margin: 0 auto; }
  .header.v2_mod .header_in {
    align-content: center;
    align-items: center;
    max-width: none; }

.header_col {
  height: 100%; }
  @media screen and (min-width: 1201px) {
    .header_col:first-child {
      width: 50%;
      padding-right: 5.2em; } }
  .header_col:last-child {
    margin-left: auto; }

.header_logo {
  display: block;
  width: 10em;
  text-decoration: none; }
  .header_logo:hover, .header_logo:active, .header_logo:focus {
    text-decoration: none;
    opacity: .8; }

.header_logo_img {
  display: block;
  width: 100%;
  height: auto; }

@media screen and (min-width: 1201px) {
  .header_nav {
    margin-left: auto; } }

@media screen and (max-width: 1200px) {
  .header_nav {
    z-index: 40;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    padding: 6rem 2em;
    overflow-x: hidden;
    overflow-y: auto;
    transition: transform .3s ease;
    will-change: transform;
    transform: translateY(-100%);
    background-color: #fff; }
    body.menu_open .header_nav {
      transform: translateY(0); } }

.header_nav_list {
  margin: 0 -3.6em -2em 0;
  padding: 0;
  list-style: none; }
  @media screen and (max-width: 767px) {
    .header_nav_list {
      flex-direction: column;
      align-items: center;
      align-content: center; } }

.header_nav_item {
  padding: 0 3.6em 2em 0; }

.header_nav_link {
  text-decoration: none;
  color: #000;
  font-weight: 600;
  line-height: .8; }
  @media screen and (min-width: 768px) {
    .header_nav_link {
      font-size: 1.6em; } }
  @media screen and (max-width: 1200px) {
    .header_nav_link {
      font-size: 3em; } }
  .header_nav_link:hover, .header_nav_link:active, .header_nav_link:focus {
    text-decoration: none;
    color: #ff5779; }

.header_side_link {
  text-decoration: none;
  color: #000;
  font-size: 1.6em;
  font-weight: 600;
  line-height: .8; }
  @media screen and (min-width: 1201px) {
    body.index_mod .header_side_link {
      color: #fff; }
    .header.scroll_mod .header_side_link {
      color: #000; } }
  @media screen and (max-width: 767px) {
    .header_side_link {
      display: none; } }
  .header_side_link:hover, .header_side_link:active, .header_side_link:focus {
    text-decoration: none;
    color: #ff5779; }
    @media screen and (min-width: 1201px) {
      body.index_mod .header_side_link:hover, body.index_mod .header_side_link:active, body.index_mod .header_side_link:focus {
        color: #ff5779; }
      .header.scroll_mod .header_side_link:hover, .header.scroll_mod .header_side_link:active, .header.scroll_mod .header_side_link:focus {
        color: #ff5779; } }

.menu_trigger {
  z-index: 210;
  position: relative;
  margin-left: 2rem;
  display: none;
  height: 2.6rem;
  width: 3.8rem;
  text-transform: uppercase;
  color: transparent;
  cursor: pointer; }
  @media screen and (max-width: 1200px) {
    .menu_trigger {
      display: block; } }
  .menu_trigger:before {
    content: '';
    transform: translate(0, -1rem);
    transition: all .3s ease;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: .4rem;
    margin-top: -.2rem;
    background: #000; }
    @media screen and (min-width: 1201px) {
      body.index_mod .menu_trigger:before {
        background: #fff; }
      .header.scroll_mod .menu_trigger:before,
      body.menu_open .menu_trigger:before {
        background: #000; } }
  .menu_trigger:after {
    content: '';
    transform: translate(0, 1rem);
    transition: all .3s ease;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: .4rem;
    margin-top: -.2rem;
    background: #000; }
    @media screen and (min-width: 1201px) {
      body.index_mod .menu_trigger:after {
        background: #fff; }
      .header.scroll_mod .menu_trigger:after,
      body.menu_open .menu_trigger:after {
        background: #000; } }
  .menu_trigger.active_mod:before {
    transform: rotate(45deg) translate(0, 0); }
  .menu_trigger.active_mod:after {
    transform: rotate(-45deg) translate(0, 0); }

.menu_trigger_decor {
  transition: all .3s ease;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: .4rem;
  margin-top: -.2rem;
  background: #000; }
  .active_mod .menu_trigger_decor {
    opacity: 0;
    transform: translate(100%, 0); }
  @media screen and (min-width: 1201px) {
    body.index_mod .menu_trigger_decor {
      background: #fff; }
    .header.scroll_mod .menu_trigger_decor,
    body.menu_open .menu_trigger_decor {
      background: #000; } }

.footer {
  width: 100%;
  min-height: 37rem;
  padding: 7em 2rem 5.4em;
  flex: 0 0 auto;
  font-size: 1rem;
  background-color: #2f2f2f;
  will-change: transform; }

.footer_top {
  margin-bottom: 4em; }
  @media screen and (max-width: 1023px) {
    .footer_top {
      flex-direction: column; } }

.footer_bottom {
  margin-top: auto; }
  @media screen and (max-width: 640px) {
    .footer_bottom {
      flex-direction: column;
      align-items: flex-start;
      align-content: flex-start; } }

.footer_logo {
  display: block;
  width: 18.8em;
  height: 2.8em;
  margin-right: auto; }
  @media screen and (max-width: 1023px) {
    .footer_logo {
      margin-bottom: 4em;
      margin-left: 0; } }

.logo_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain; }

.footer_form_block {
  width: 53.6em;
  max-width: 100%;
  margin: 0 auto; }
  .footer_form_block.index_mod {
    margin-right: 0; }
    @media screen and (max-width: 1023px) {
      .footer_form_block.index_mod {
        margin-bottom: 0; } }
  @media screen and (max-width: 1023px) {
    .footer_form_block {
      width: 100%;
      margin-bottom: 4em; } }

.footer_form_title {
  margin-bottom: 1.66667em;
  font-size: 1.8em;
  color: #fff; }
  @media screen and (max-width: 1023px) {
    .footer_form_title {
      margin-bottom: 1em; } }

.footer_form_controller {
  position: relative;
  flex-grow: 1; }

.form_icon {
  position: absolute;
  top: 50%;
  left: 1.4em;
  width: 2.6em;
  height: 2em;
  margin-top: -1em;
  z-index: 2; }

.footer_social.index_mod {
  margin-left: auto; }
  @media screen and (max-width: 640px) {
    .footer_social.index_mod {
      margin-left: 0;
      margin-bottom: 2em; } }

.social_item {
  margin-right: 2em; }
  .social_item:last-child {
    margin-right: 0; }

.social_link {
  transition: color .3s; }
  .social_link.footer_mod {
    display: block;
    height: 1.8em;
    width: 1.8em;
    color: #fff; }
    .social_link.footer_mod:hover, .social_link.footer_mod:focus {
      color: #ff5779; }

@media screen and (max-width: 640px) {
  .footer_copyright {
    order: 2; } }

.footer_copyright_top {
  margin-bottom: 0.33333em;
  font-size: 1.2em;
  color: #fff; }

.footer_copyright_bottom {
  font-size: 1.2em;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase; }

.form_field.default_mod {
  display: block;
  width: 100%;
  height: 5rem;
  padding: 0 2rem;
  font-size: 1.6rem;
  font-family: "Avenir", sans-serif;
  color: #000;
  border: none;
  border-radius: 0; }
  .form_field.default_mod::-webkit-input-placeholder {
    font-style: italic;
    color: rgba(0, 0, 0, 0.5); }
  .form_field.default_mod::-moz-placeholder {
    font-style: italic;
    color: rgba(0, 0, 0, 0.5); }
  .form_field.default_mod:-ms-input-placeholder {
    font-style: italic;
    color: rgba(0, 0, 0, 0.5); }

.form_field.default_mod:focus {
  box-shadow: inset 0 0 0 1px #14e7fa; }

.form_cell.hidden_field {
  display: none; }

.form_cell.form_cell_v2_mod {
  position: relative; }

.form_cell_title.hline_hide_mod {
  display: none; }

.form_cell_title.form_v2_mod {
  z-index: 1;
  order: 2;
  color: #000;
  font-size: 1.6rem;
  font-weight: 400;
  opacity: .5;
  pointer-events: none; }
  @media screen and (min-width: 768px) {
    .form_cell_title.form_v2_mod {
      transition: transform .3s ease;
      will-change: transform; } }
  @media screen and (max-width: 767px) {
    .form_cell_title.form_v2_mod {
      transition: opacity .3s ease;
      will-change: opacity; } }
  @media screen and (min-width: 768px) {
    .form_cell_title.form_v2_mod.active_mod {
      transform: translateY(-4.2rem); } }
  @media screen and (max-width: 767px) {
    .form_cell_title.form_v2_mod.active_mod {
      opacity: 0; } }

.form_field_wrap {
  position: relative; }
  @media screen and (max-width: 767px) {
    .form_field_wrap.form_v2_mod {
      transition: border-color .3s ease;
      border-bottom: 1px solid transparent; } }
  .form_field_wrap.form_v2_mod.active_mod {
    border-color: rgba(33, 43, 54, 0.5); }

.form_field.hero_mod {
  display: block;
  width: 100%;
  height: 4rem;
  padding: 0 2rem 0 0;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "Avenir", sans-serif;
  color: #212b36;
  box-shadow: none;
  border: none;
  border-radius: 0; }
  .form_field.hero_mod::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  .form_field.hero_mod::-moz-placeholder {
    color: rgba(0, 0, 0, 0.5); }
  .form_field.hero_mod:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }

.form_field.icon_mod {
  padding-left: 5rem; }

.section {
  position: relative;
  width: 100%;
  font-size: 1rem; }
  @media screen and (min-width: 768px) {
    .section {
      padding: 8em 2rem; } }
  @media screen and (max-width: 767px) {
    .section {
      padding: 4em 2rem; } }
  .section.no_offset_mod {
    padding: 0 2rem; }
  .section.lg_offset_bottom {
    padding-bottom: 32em; }

.section_in {
  max-width: 116rem;
  width: 100%;
  margin: 0 auto; }
  .section_in.v2_mod {
    max-width: 120rem; }
  .section_in.hero_mod {
    height: 100vh;
    min-height: 90em; }
  .section_in.footer_mod {
    flex-grow: 1;
    max-width: 122.4rem; }
  .section_in.flex_v2_mod {
    height: 100%; }

@media screen and (min-width: 1201px) {
  .section_col {
    width: 50%; } }

@media screen and (max-width: 1200px) {
  .section_col {
    width: 100%; }
    .section_col:first-child {
      margin-bottom: 8em; } }

.section_title {
  width: 100%;
  color: #000;
  font-family: "Circular", sans-serif;
  font-weight: 900;
  line-height: 1.2; }
  @media screen and (min-width: 768px) {
    .section_title {
      font-size: 5em; } }
  @media screen and (max-width: 767px) {
    .section_title {
      font-size: 4em; } }
  .section_title.offset_mod {
    margin-bottom: 0.88em; }
  .section_title.offset_2_mod {
    margin-bottom: 1.52em; }
  .section_title.offset_3_mod {
    margin-bottom: 2.48em; }
  .section_title.limit_mod {
    max-width: 13.48em; }
  .section_title.decor_mod {
    position: relative;
    padding-left: 0.64em; }
    @media screen and (max-width: 1300px) {
      .section_title.decor_mod {
        padding-left: 6rem; } }
  .section_title.slider_mod {
    width: 56.2%;
    padding-left: 7.8rem;
    margin-left: auto; }
    @media screen and (max-width: 1023px) {
      .section_title.slider_mod {
        width: 100%;
        padding: 0;
        margin-bottom: 10vw; } }
  .section_title span {
    color: #14e7fa; }

.section_descr {
  color: #212b36;
  font-size: 1.8em;
  font-weight: 400; }
  .section_descr p {
    margin-bottom: 1.66667em; }
    .section_descr p:last-child {
      margin-bottom: 0; }
  .section_descr strong {
    font-weight: 600;
    font-style: italic; }
  @media screen and (min-width: 768px) {
    .section_descr.side_offset_mod {
      padding-left: 2.77778em; } }

.icon_w {
  display: block; }
  .icon_w.header_link_mod {
    width: 1.625em;
    height: 1.25em;
    margin-right: 1em; }

.icon.size_mod {
  display: block;
  width: 100%;
  height: 100%; }

.advisors_block {
  margin-bottom: 9.6em; }
  @media screen and (max-width: 767px) {
    .advisors_block {
      flex-wrap: wrap; } }

.advisors_img_wrap {
  width: 55.6%; }
  @media screen and (max-width: 1023px) {
    .advisors_img_wrap {
      width: 50%; } }
  @media screen and (max-width: 767px) {
    .advisors_img_wrap {
      width: 100%; } }

.advisors_img {
  display: block;
  width: 100%; }

.advisors_text {
  width: 44.4%;
  max-width: 38.6rem;
  padding-left: 4.125em;
  font-size: 1.6em;
  line-height: 1.4;
  color: #293846; }
  .advisors_text p {
    margin-bottom: 1.25em; }
    .advisors_text p:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 1023px) {
    .advisors_text {
      width: 50%;
      max-width: 100%;
      padding-left: 2.4em; } }
  @media screen and (max-width: 767px) {
    .advisors_text {
      width: 100%;
      padding-left: 0;
      margin-top: 2em; } }

.advisors_list {
  margin: 0 -4.8em; }
  @media screen and (max-width: 1023px) {
    .advisors_list {
      margin: 0;
      justify-content: center;
      flex-wrap: wrap; } }

.advisor_item {
  position: relative;
  width: 37em;
  max-width: 33.33%;
  padding: 6.4em 4.8em;
  margin-right: auto; }
  .advisor_item:last-child {
    margin-right: 0; }
  .advisor_item.bg_mod {
    background-color: #fff;
    box-shadow: 0 0 5em rgba(0, 0, 0, 0.15); }
  @media screen and (max-width: 1023px) {
    .advisor_item {
      width: 100%;
      max-width: 100%;
      text-align: center; } }

.advisor_decor {
  position: absolute;
  top: 1em;
  left: -1.6em;
  width: 15.4em;
  height: 16.4em;
  background: url("../i/advisor_decor.png") repeat top left;
  background-size: 20px 20px;
  opacity: .05; }

.advisor_title {
  height: 2.4em;
  margin-bottom: 1.8em;
  font-size: 4em;
  line-height: 1.2;
  font-weight: 900;
  font-family: "Circular", sans-serif;
  overflow: hidden; }
  @media screen and (max-width: 1023px) {
    .advisor_title {
      height: auto;
      margin-bottom: 1em; }
      .advisor_title br {
        display: none; } }

.advisor_mail {
  margin-bottom: 3.5em;
  font-size: 2em;
  color: #25313e; }
  @media screen and (max-width: 1023px) {
    .advisor_mail {
      margin-bottom: 2em; } }

.roadmap_list {
  position: relative;
  margin-right: -2.4em; }
  .roadmap_list:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 200vw;
    height: 1px;
    margin-left: -100vw;
    background-color: #000;
    opacity: .25; }
    @media screen and (max-width: 767px) {
      .roadmap_list:after {
        left: auto;
        right: calc(100% - .8em);
        top: -2em;
        height: calc(100% + 4em);
        background-color: transparent;
        border: 1px solid #000; } }

.roadmap_item {
  position: relative;
  width: 25%;
  padding-top: 3.4em;
  padding-right: 2.4em; }
  .roadmap_item:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1.6em;
    height: 1.6em;
    margin-top: -.8em;
    background-color: #ff5779;
    border-radius: 50%;
    z-index: 2; }
    @media screen and (max-width: 767px) {
      .roadmap_item:after {
        margin-top: .4rem; } }
  @media screen and (max-width: 767px) {
    .roadmap_item {
      width: 100%;
      padding: 0 0 0 3em; }
      .roadmap_item + .roadmap_item {
        margin-top: 3em; } }

.roadmap_year {
  margin-bottom: 0.72727em;
  font-size: 2.2em;
  font-weight: 600;
  color: #212b36; }

.roadmap_text {
  font-size: 1.4em;
  line-height: 1.4;
  color: #212b36; }

.title_decor {
  position: absolute;
  top: 10em;
  left: -.6em; }
  @media screen and (max-width: 767px) {
    .title_decor {
      top: 6em; } }

.title_decor_item {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: #ff5779; }
  .title_decor_item + .title_decor_item {
    margin-left: 1rem; }
  .title_decor_item:nth-child(1) {
    background-color: #000;
    opacity: .25; }
  .title_decor_item:nth-child(2) {
    background-color: #000; }
  .title_decor_item:nth-child(3) {
    background-color: #14e7fa; }

.why_slider_wrap {
  position: relative;
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 0 2rem;
  counter-reset: why;
  background-color: #14e7fa; }

.why_item {
  height: inherit !important;
  padding: 8.3em 0 6.6em; }
  @media screen and (max-width: 860px) {
    .why_item {
      padding: 4em 0; } }

.why_col.img_mod {
  position: relative;
  width: 43.8%; }
  @media screen and (max-width: 767px) {
    .why_col.img_mod {
      display: none; } }

.why_col.content_mod {
  width: 56.2%;
  padding-left: 7.8rem; }
  @media screen and (max-width: 1023px) {
    .why_col.content_mod {
      padding-left: 3em; } }
  @media screen and (max-width: 767px) {
    .why_col.content_mod {
      width: 100%;
      padding-left: 0; } }

.why_img_wrap {
  position: absolute;
  top: 50%;
  width: calc(100% + 4rem);
  margin: -2rem;
  border-radius: 16.8rem;
  border: 2rem solid #fff;
  transform: translateY(-54%);
  overflow: hidden; }
  .why_img_wrap:before {
    content: '';
    position: absolute;
    top: -2rem;
    bottom: -2rem;
    left: -2rem;
    right: -2rem;
    background-color: #fff;
    border-radius: 16.8rem;
    z-index: 1; }
    @media screen and (max-width: 1023px) {
      .why_img_wrap:before {
        border-radius: 16vw; } }
  .why_img_wrap:after {
    content: '';
    display: block;
    padding-bottom: 125%; }
  @media screen and (max-width: 1200px) {
    .why_img_wrap {
      width: 100%;
      margin: 0; } }
  @media screen and (max-width: 1023px) {
    .why_img_wrap {
      border-radius: 16vw;
      transform: translateY(-50%); } }

.why_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 2; }

.why_text {
  position: relative;
  padding-left: 5.33333em;
  margin-bottom: 1.88889em;
  font-size: 1.8em;
  font-weight: 500;
  color: #212b36; }
  .why_text:before {
    counter-increment: why;
    content: counter(why);
    position: absolute;
    top: 0;
    left: 0;
    font-size: 11.6rem;
    line-height: .8;
    font-family: "Circular", sans-serif;
    font-weight: 700;
    color: #fff; }

.why_control {
  position: absolute;
  top: 50%;
  margin-top: -.8rem;
  display: block;
  width: 3em;
  height: 1.6em;
  color: #fff;
  cursor: pointer;
  z-index: 10; }
  .why_control.prev_mod {
    left: 2rem; }
  .why_control.next_mod {
    right: 2rem; }
  .why_control.slick-disabled {
    opacity: 0;
    pointer-events: none; }
  .why_control:hover, .why_control:focus {
    text-decoration: none;
    color: #ff5779; }
  @media screen and (max-width: 1300px) {
    .why_control {
      top: auto;
      bottom: 5rem; }
      .why_control.prev_mod {
        left: auto;
        right: 12rem; }
      .why_control.next_mod {
        right: 2rem; } }
  @media screen and (max-width: 1023px) {
    .why_control {
      bottom: 3rem; } }

.hero_bg {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%; }
  @media screen and (min-width: 1201px) {
    .hero_bg {
      width: 50%; } }
  @media screen and (max-width: 1200px) {
    .hero_bg {
      width: 100%; }
      .hero_bg:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: .8; } }

.hero_bg_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.section_head {
  width: 100%;
  margin-bottom: 4.4em; }
  @media screen and (min-width: 768px) {
    .section_head {
      max-width: 50.2em; } }

.hero_form_w {
  box-shadow: 0 0 5em rgba(0, 0, 0, 0.15);
  background-color: #fff; }
  @media screen and (min-width: 768px) {
    .hero_form_w {
      width: 70em;
      padding: 3.4em; } }
  @media screen and (max-width: 767px) {
    .hero_form_w {
      width: 100%;
      padding: 2em; } }

.hero_form {
  width: 100%; }
  @media screen and (max-width: 767px) {
    .hero_form {
      flex-direction: column;
      align-items: center;
      align-content: center; } }

@media screen and (min-width: 768px) {
  .hero_form_field_w {
    flex: 1 0 auto; } }

@media screen and (max-width: 767px) {
  .hero_form_field_w {
    width: 100%;
    margin-bottom: 1em; } }

.quote_block_w {
  width: 100%; }
  @media screen and (min-width: 1201px) {
    .quote_block_w {
      min-height: 53.4em; } }
  @media screen and (max-width: 1200px) {
    .quote_block_w {
      padding: 10em 4em 0 0;
      justify-content: flex-end; } }

.quote_block {
  position: relative;
  width: 100%;
  min-height: 26.6em;
  padding: 3em;
  box-shadow: 0 0 5em rgba(0, 0, 0, 0.15);
  background-color: #fff; }
  @media screen and (min-width: 768px) {
    .quote_block {
      max-width: 49.8em; } }
  .quote_block:before {
    z-index: -1;
    position: absolute;
    top: -4.8em;
    left: 4.2em;
    content: '';
    width: 100%;
    height: 92%;
    background-color: #14e7fa; }
  .quote_block:after {
    position: absolute;
    top: -7.6em;
    right: 2.8em;
    content: '';
    width: 4.2em;
    height: 4.2em;
    background-image: url("../i/decors/sml_decor.svg");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat; }

.quote_block_in {
  width: 100%;
  max-width: 14.8em;
  color: #212b36;
  font-weight: 700;
  font-size: 2.5em; }
  .quote_block_in p:before, .quote_block_in p:after {
    content: '“'; }

.tools_list_w {
  width: 100%; }

.tools_list {
  margin: 0 -3.6em -3.6em 0; }

.tools_item {
  padding: 0 3.6em 3.6em 0; }
  @media screen and (min-width: 1025px) {
    .tools_item {
      width: 33.33%; } }
  @media screen and (max-width: 1024px) {
    .tools_item {
      width: 100%; } }

.tools_item_in {
  width: 100%;
  height: 100%;
  border-radius: 2em;
  background-color: #f9f9f9;
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    .tools_item_in {
      flex-direction: row; } }

.tools_item_text {
  padding: 4.4em 3em; }
  .tools_item.top_img_mod .tools_item_text {
    order: 2; }
    @media screen and (min-width: 1025px) {
      .tools_item.top_img_mod .tools_item_text {
        margin-top: auto; } }
  @media (min-width: 480px) and (max-width: 1024px) {
    .tools_item_text {
      width: 60%;
      height: 100%; } }
  @media screen and (max-width: 479px) {
    .tools_item_text {
      width: 100%; } }

@media screen and (min-width: 1025px) {
  .tools_img_w {
    width: 100%;
    height: 29.2em;
    margin-top: auto; } }

.tools_item.top_img_mod .tools_img_w {
  order: 1; }
  @media screen and (min-width: 1025px) {
    .tools_item.top_img_mod .tools_img_w {
      height: 35.6em;
      margin-top: 4.4em; } }

@media (min-width: 480px) and (max-width: 1024px) {
  .tools_img_w {
    width: 40%;
    height: 100%; } }

@media screen and (max-width: 479px) {
  .tools_img_w {
    width: 100%;
    height: 100vw; } }

.tools_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.tools_item_title {
  width: 100%;
  margin-bottom: 1.11111em;
  color: #000;
  font-family: "Circular", sans-serif;
  font-size: 1.8em;
  font-weight: 500; }

.tools_descr {
  width: 100%;
  margin-bottom: 1.11111em;
  color: #212b36;
  font-weight: 400;
  line-height: 1.4; }
  @media screen and (min-width: 768px) {
    .tools_descr {
      font-size: 1.8em; } }
  @media screen and (max-width: 767px) {
    .tools_descr {
      font-size: 1.6em; } }
  .tools_descr:last-child {
    margin-bottom: 0; }
  .tools_descr p {
    margin-bottom: 1.11111em; }
    .tools_descr p:last-child {
      margin-bottom: 0; }
  .tools_descr strong {
    font-weight: 600;
    font-style: italic; }

.more_link {
  position: relative;
  padding: 0 0.76923em 0.76923em 0;
  text-decoration: none;
  display: inline-block;
  color: #000;
  font-size: 1.3em;
  font-weight: 700;
  overflow: hidden; }
  .more_link:before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 0.38462em;
    background-color: #17eeff; }
  .more_link:hover, .more_link:active, .more_link:focus {
    text-decoration: none; }
    .more_link:hover:before, .more_link:active:before, .more_link:focus:before {
      transform: translateX(-60%); }

.privacy_col:first-child {
  position: relative; }
  @media screen and (max-width: 1025px) {
    .privacy_col:first-child {
      flex: none;
      width: 24em;
      margin-right: 2.4em; } }
  @media screen and (max-width: 1024px) {
    .privacy_col:first-child {
      width: 100%;
      margin-bottom: 4em; } }

.privacy_col:last-child {
  box-shadow: 2px 4px 2em rgba(34, 90, 89, 0.2);
  border-radius: .8em;
  overflow: hidden; }
  @media screen and (min-width: 1025px) {
    .privacy_col:last-child {
      margin-left: auto;
      width: 72.4%;
      padding: 5em; } }
  @media screen and (max-width: 1024px) {
    .privacy_col:last-child {
      width: 100%;
      padding: 2em; } }

.privacy_nav {
  width: 24em; }
  @media screen and (min-width: 1025px) {
    .privacy_nav.fixed_mod {
      position: fixed;
      top: 9rem; } }

.privacy_nav_list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none; }

.privacy_nav_item {
  margin-bottom: 1.2em; }
  .privacy_nav_item:last-child {
    margin-bottom: 0; }

.privacy_nav_link {
  position: relative;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.6em;
  line-height: 1.33;
  color: #000; }
  .privacy_nav_link:before {
    position: absolute;
    left: -0.5em;
    bottom: 0;
    content: '';
    width: 2px;
    height: 100%;
    background-color: #fe2c55;
    opacity: 0; }
  .privacy_nav_item.active_mod .privacy_nav_link:before {
    opacity: 1; }
  .privacy_nav_link:hover, .privacy_nav_link:active, .privacy_nav_link:focus {
    text-decoration: none; }
    .privacy_nav_link:hover:before, .privacy_nav_link:active:before, .privacy_nav_link:focus:before {
      opacity: 1; }

.privacy_content {
  width: 100%; }
  .privacy_content h1 {
    font-weight: 700;
    font-size: 4.8rem;
    line-height: 1; }
    @media screen and (min-width: 768px) {
      .privacy_content h1 {
        font-size: 4.8rem; } }
    @media screen and (max-width: 767px) {
      .privacy_content h1 {
        font-size: 2.8rem; } }
  .privacy_content h2 {
    font-weight: 700;
    font-size: 3.4rem;
    line-height: 1;
    margin-bottom: 1.6rem; }
    @media screen and (min-width: 768px) {
      .privacy_content h2 {
        font-size: 3.4rem; } }
    @media screen and (max-width: 767px) {
      .privacy_content h2 {
        font-size: 2rem; } }
  .privacy_content h3 {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1;
    margin-bottom: 1.6rem; }
    @media screen and (min-width: 768px) {
      .privacy_content h3 {
        font-size: 2.4rem; } }
    @media screen and (max-width: 767px) {
      .privacy_content h3 {
        font-size: 1.8rem; } }
  .privacy_content h4 {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.5em;
    margin-bottom: 3rem; }
  .privacy_content p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5em;
    margin-bottom: 3rem; }
  .privacy_content ul {
    margin: 0 0 3rem;
    padding-left: 4rem;
    list-style: disc; }
  .privacy_content li {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5em;
    margin-bottom: 1.6rem;
    list-style: disc; }
  .privacy_content ul ul {
    list-style: circle; }
  .privacy_content ul ul li {
    list-style: circle; }
  .privacy_content a {
    text-decoration: underline;
    font-weight: 500; }

@font-face {
  font-family: "iconfont";
  src: url("../fonts/icons/iconfont.eot");
  src: url("../fonts/icons/iconfont.eot?#iefix") format("eot"), url("../fonts/icons/iconfont.woff") format("woff"), url("../fonts/icons/iconfont.ttf") format("truetype"), url("../fonts/icons/iconfont.svg#iconfont") format("svg"); }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor; }

.icon-facebook {
  font-size: 1.6rem;
  width: 0.46687em; }

.icon-instagram {
  font-size: 1.6rem;
  width: 1em; }

.icon-kakao {
  font-size: 46.937rem;
  width: 1.09082em; }

.icon-left {
  font-size: 1.6rem;
  width: 1.8125em; }

.icon-linkedin {
  font-size: 1.528rem;
  width: 1.04712em; }

.icon-mail {
  font-size: 2rem;
  width: 1.3em; }

.icon-medium {
  font-size: 40.533rem;
  width: 1.26317em; }

.icon-right {
  font-size: 1.6rem;
  width: 1.8125em; }

.icon-telegram {
  font-size: 2.8rem;
  width: 1em; }

.icon-twitter {
  font-size: 1.322rem;
  width: 1.21029em; }

.icon-whitepaper {
  font-size: 2rem;
  width: 1.3em; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }
  .why_slider .slick-list {
    overflow: visible; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .why_slider .slick-track {
    display: flex !important; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots {
  margin-top: 2rem;
  width: 100%; }
  .slick-dots li {
    padding: 0 .6em; }
    .slick-dots li.slick-active button {
      background-color: #fff; }
  .slick-dots button {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    font-size: 0;
    border-radius: 50%;
    border: 2px solid #fff;
    cursor: pointer; }
    .slick-dots button:hover, .slick-dots button:focus {
      background-color: #fff; }
