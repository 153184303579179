.hero_bg {
	z-index: -1;

	position: absolute;
	top: 0;
	right: 0;

	height: 100%;

	@include bp($point_1 + 1, min) {
		width: 50%;
	}

	@include bp($point_1) {
		width: 100%;

		&:before {
			position: absolute;
			top: 0;
			left: 0;

			content: '';

			width: 100%;
			height: 100%;

			background-color: $white;
			opacity: .8;
		}
	}
}

.hero_bg_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.section_content {}

.section_head {
	width: 100%;
	margin-bottom: 4.4em;

	@include bp($point_4, min) {
		max-width: 50.2em;
	}
}

.hero_form_w {

	box-shadow: 0 0 5em rgba($black, .15);
	background-color: $white;

	@include bp($point_4, min) {
		width: 70em;
		padding: 3.4em;
	}

	@include bp($point_4 - 1) {
		width: 100%;
		padding: 2em;
	}
}

.hero_form {
	@extend %global_flex_block_row_wrap_flex-start_flex-end;

	width: 100%;

	@include bp($point_4 - 1) {
		flex-direction: column;
		align-items: center;
		align-content: center;
	}
}

.hero_form_field_w {
	@include bp($point_4, min) {
		flex: 1 0 auto;
	}

	@include bp($point_4 - 1) {
		width: 100%;
		margin-bottom: 1em;
	}
}