//---------------------------------------------fonts
$font_path: "../fonts/";
$font_1: 'Avenir', sans-serif;
$circular: 'Circular', sans-serif;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;
$black_2: #2f2f2f;
$red: #ff5779;
$blue: #14e7fa;
$blue_2: #17eeff;
$gray: #212b36;
$gray_2: #f9f9f9;
$gray_3: #293846;
$gray_4: #edf6f7;
$gray_5: #b6b6b6;

$color_text_base: $black;
$color_link_base:#4aa3df;
$body_bg: $white;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popap : 9999,
	header : 100,
	footer : 100
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$height_footer: 37rem;
$height_header: 10rem;
$height_header_2: 7rem;
$page_width: 116rem;

$header_mobile: 6rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_indent: 2rem;
$gl_radius:.5rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 5rem;
$input_offset: 0 2rem;
$input_font_size: $font_size_base;
$input_border_color: #ccc;
$input_border_color_active: $black;
$input_placeholder_color: rgba($black, .5);
$input_text_color: $black;
$input_radius: 0;
//---------------------------------------------forms###