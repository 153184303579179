.tools_list_w {
	width: 100%;
}

.tools_list {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	margin: 0 -3.6em -3.6em 0;
}

.tools_item {
	padding: 0 3.6em 3.6em 0;

	@include bp($point_2 + 1, min) {
		width: 33.33%;
	}

	@include bp($point_2) {
		width: 100%;
	}
}

.tools_item_in {
	@extend %global_flex_block_column_wrap_flex-start;

	width: 100%;
	height: 100%;
	//min-height: 75em;

	border-radius: 2em;
	background-color: $gray_2;

	overflow: hidden;

	@include bp($point_2) {
		flex-direction: row;
	}

}

.tools_item_text {
	padding: 4.4em 3em;

	.tools_item.top_img_mod & {
		order: 2;

		@include bp($point_2 + 1, min) {
			margin-top: auto;
		}
	}

	@media (min-width: $point_6) and (max-width: $point_2) {
		width: 60%;
		height: 100%;
	}

	@include bp($point_6 - 1) {
		width: 100%;
	}
}

.tools_img_w {
	//display: flex;
	//justify-content: flex-end;
	//align-items: flex-end;
	//align-content: flex-end;

	@include bp($point_2 + 1, min) {
		width: 100%;
		height: 29.2em;
		margin-top: auto;
	}

	.tools_item.top_img_mod & {
		order: 1;

		@include bp($point_2 + 1, min) {
			height: 35.6em;
			margin-top: 4.4em;
		}
	}

	@media (min-width: $point_6) and (max-width: $point_2) {
		width: 40%;
		height: 100%;
	}

	@include bp($point_6 - 1) {
		width: 100%;
		height: 100vw;
	}
}

.tools_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.tools_item_title {
	width: 100%;
	margin-bottom: (2em/1.8);

	color: $black;
	font-family: $circular;
	font-size: 1.8em;
	font-weight: 500;
}

.tools_descr {
	width: 100%;
	margin-bottom: (2em/1.8);

	color: $gray;
	font-weight: 400;
	line-height: 1.4;

	@include bp($point_4, min) {
		font-size: 1.8em;
	}

	@include bp($point_4 - 1) {
		font-size: 1.6em;
	}

	&:last-child {
		margin-bottom: 0;
	}

	p {
		margin-bottom: (2em/1.8);

		&:last-child {
			margin-bottom: 0;
		}
	}

	strong {
		font-weight: 600;
		font-style: italic;
	}
}

.more_link {
	position: relative;
	padding: 0 (1em/1.3) (1em/1.3) 0;

	text-decoration: none;

	display: inline-block;
	color: $black;
	font-size: 1.3em;
	font-weight: 700;

	overflow: hidden;

	&:before {
		@extend %transition_transform;

		position: absolute;
		bottom: 0;
		left: 0;

		content: '';

		width: 100%;
		height: (.5em/1.3);

		background-color: $blue_2;
	}

	&:hover, &:active, &:focus {
		text-decoration: none;

		&:before {
			transform: translateX(-60%);
		}
	}
}