//------------------------------------------------------------layout
.header {
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;

	font-size: 1rem;

	will-change: transform;

	&:before {
		@extend %transition_opacity;
		z-index: -1;

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;


		content: '';

		box-shadow: 0 2px 1em 0 rgba($black, .2);
		background-color: $white;
		opacity: 0;

	}

	&.scroll_mod {
		&:before {
			opacity: 1;
		}
	}

	&:not(.v2_mod) {
		padding: 0 $gl_indent;

		@include bp($point_1 + 1, min) {
			height: $height_header;
		}

		@include bp($point_1) {
			height: $header_mobile;
		}
	}

	&.v2_mod {
		height: $height_header_2;

		border-bottom: 1px solid rgba(34,90,89,0.2);

		@include bp($point_2 + 1, min) {
			padding: 0 8rem;
		}

		@include bp($point_2) {
			padding: 0 $gl_indent;
		}
	}

	@include z-index(header);
}

.header_in {
	@extend %global_flex_block_row_wrap_flex-start;

	position: relative;

	width: 100%;
	height: 100%;
	max-width: 135.4rem;
	margin: 0 auto;

	.header.v2_mod & {
		align-content: center;
		align-items: center;
		max-width: none;
	}
}

.header_col {
	@extend %global_flex_block_row_wrap_flex-start_center;
	height: 100%;

	&:first-child {
		@include bp($point_1 + 1, min) {
			width: 50%;
			padding-right: 5.2em;
		}
	}

	&:last-child {
		margin-left: auto;
	}
}

.header_logo {
	@extend %transition_opacity;

	display: block;
	width: 10em;

	text-decoration: none;

	&:hover, &:active, &:focus {
		text-decoration: none;
		opacity: .8;
	}
}

.header_logo_img {
	display: block;
	width: 100%;
	height: auto;
}

.header_nav {
	@include bp($point_1 + 1, min) {
		margin-left: auto;
	}

	@include bp($point_1) {
		z-index: 40;

		position: fixed;
		top: 0;
		left: 0;

		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;

		width: 100%;
		height: 100vh;
		max-height: 100vh;
		padding: $header_mobile 2em;

		overflow-x: hidden;
		overflow-y: auto;

		transition: transform .3s ease;
		will-change: transform;

		transform: translateY(-100%);

		background-color: $white;

		body.menu_open & {
			transform: translateY(0);
		}
	}

}

.header_nav_list {
	@extend %global_flex_block_row_wrap_flex-start;

	margin: 0 -3.6em -2em 0;
	padding: 0;

	list-style: none;

	@include bp($point_4 - 1) {
		flex-direction: column;
		align-items: center;
		align-content: center;
	}
}

.header_nav_item {
	padding: 0 3.6em 2em 0;
}

.header_nav_link {
	@extend %transition_color;

	text-decoration: none;
	color: $black;
	font-weight: 600;
	line-height: .8;

	@include bp($point_4, min) {
		font-size: 1.6em;
	}

	@include bp($point_1) {
		font-size: 3em;
	}

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $red;
	}
}

.header_side_link {
	@extend %global_flex_block_row_wrap_flex-start_center;
	@extend %transition_color;

	text-decoration: none;
	color: $black;
	font-size: 1.6em;
	font-weight: 600;
	line-height: .8;

	@include bp($point_1 + 1, min) {
		body.index_mod & {
			color: $white;
		}

		.header.scroll_mod & {
			color: $black;
		}
	}

	@include bp($point_4 - 1) {
		display: none;
	}


	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $red;

		@include bp($point_1 + 1, min) {
			body.index_mod & {
				color: $red;
			}

			.header.scroll_mod & {
				color: $red;
			}
		}
	}
}
//------------------------------------------------------------layout###
//---------------------------------------------trigger
@mixin user_menu_butt_line {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: .4rem;
	margin-top: -.2rem;
	background: $black;

	@include bp($point_1 + 1, min) {
		body.index_mod & {
			background: $white;
		}

		.header.scroll_mod &,
		body.menu_open & {
			background: $black;
		}
	}
}

.menu_trigger {
	z-index: 210;
	position: relative;
	margin-left: $gl_indent;
	display: none; // hidden on wide screens
	height: 2.6rem;
	width: 3.8rem;
	text-transform: uppercase;

	color: transparent;
	cursor: pointer;

	@include bp($point_1) {
		display: block; // appear on tablets
	}

	&:before {
		content: '';
		transform: translate(0, -1rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&:after {
		content: '';
		transform: translate(0, 1rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&.active_mod {
		&:before {
			transform: (rotate(45deg) translate(0, 0));
		}

		&:after {
			transform: (rotate(-45deg) translate(0, 0));
		}
	}
}

.menu_trigger_decor {
	transition: all .3s ease;

	.active_mod & {
		opacity: 0;
		transform: translate(100%, 0);
	}

	@include user_menu_butt_line;
}

//---------------------------------------------trigger###