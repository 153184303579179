.privacy_w {
	@extend %global_flex_block_row_wrap_flex-start_stretch;
}

.privacy_col {
	&:first-child {
		position: relative;

		@include bp($point_2 + 1) {
			flex: none;
			width: 24em;
			margin-right: 2.4em;
		}

		@include bp($point_2) {
			width: 100%;
			margin-bottom: 4em;
		}
	}

	&:last-child {

		box-shadow: 2px 4px 2em rgba(34,90,89, .2);
		border-radius: .8em;

		overflow: hidden;

		@include bp($point_2 + 1, min) {
			margin-left: auto;
			width: 72.4%;
			padding: 5em;
		}

		@include bp($point_2) {
			width: 100%;
			padding: 2em;
		}
	}
}

.privacy_nav {
	width: 24em;

	@include bp($point_2 + 1, min) {
		&.fixed_mod {
			position: fixed;
			top: $height_header_2 + 2rem;
		}
	}
}

.privacy_nav_list {
	width: 100%;

	margin: 0;
	padding: 0;

	list-style: none;
}

.privacy_nav_item {
	margin-bottom: 1.2em;

	&:last-child {
		margin-bottom: 0;
	}
}

.privacy_nav_link {
	position: relative;

	text-decoration: none;
	font-weight: 500;
	font-size: 1.6em;
	line-height: 1.33;
	color: $black;

	&:before {
		@extend %transition_opacity;

		position: absolute;
		left: (-.8em/1.6);
		bottom: 0;

		content: '';

		width: 2px;
		height: 100%;
		background-color: #fe2c55;

		opacity: 0;
	}

	.privacy_nav_item.active_mod & {
		&:before {
			opacity: 1;
		}
	}

	&:hover, &:active, &:focus {
		text-decoration: none;

		&:before {
			opacity: 1;
		}
	}
}

.privacy_content {
	width: 100%;

	h1 {
		font-weight: 700;
		font-size: 4.8rem;
		line-height: 1;

		@include bp($point_4, min) {
			font-size: 4.8rem;
		}

		@include bp($point_4 - 1) {
			font-size: 2.8rem;
		}
	}

	h2 {
		font-weight: 700;
		font-size: 3.4rem;
		line-height: 1;
		margin-bottom: 1.6rem;

		@include bp($point_4, min) {
			font-size: 3.4rem;
		}

		@include bp($point_4 - 1) {
			font-size: 2rem;
		}
	}

	h3 {
		font-weight: 700;
		font-size: 2.4rem;
		line-height: 1;
		margin-bottom: 1.6rem;

		@include bp($point_4, min) {
			font-size: 2.4rem;
		}

		@include bp($point_4 - 1) {
			font-size: 1.8rem;
		}
	}

	h4 {
		font-weight: 700;
		font-size: 1.6rem;
		line-height: (2.4em/1.6);
		margin-bottom: 3rem;
	}

	p {
		font-weight: 400;
		font-size: 1.6rem;
		line-height: (2.4em/1.6);
		margin-bottom: 3rem;
		//color: #4f525a;
	}

	ul {
		margin: 0 0 3rem;
		padding-left: 4rem;

		list-style: disc;
	}

	li {
		font-weight: 400;
		font-size: 1.6rem;
		line-height: (2.4em/1.6);
		margin-bottom: 1.6rem;

		list-style: disc;
	}

	ul ul {
		list-style: circle;
	}

	ul ul li {
		list-style: circle;
	}
	
	a {
		text-decoration: underline;
		font-weight: 500;
	}
}