.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-facebook {
	font-size:(16/10)*1rem;
	width:(7.47/16)*1em;
}
.icon-instagram {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icon-kakao {
	font-size:(469.37/10)*1rem;
	width:(512/469.37)*1em;
}
.icon-left {
	font-size:(16/10)*1rem;
	width:(29/16)*1em;
}
.icon-linkedin {
	font-size:(15.28/10)*1rem;
	width:(16/15.28)*1em;
}
.icon-mail {
	font-size:(20/10)*1rem;
	width:(26/20)*1em;
}
.icon-medium {
	font-size:(405.33/10)*1rem;
	width:(512/405.33)*1em;
}
.icon-right {
	font-size:(16/10)*1rem;
	width:(29/16)*1em;
}
.icon-telegram {
	font-size:(28/10)*1rem;
	width:(28/28)*1em;
}
.icon-twitter {
	font-size:(13.22/10)*1rem;
	width:(16/13.22)*1em;
}
.icon-whitepaper {
	font-size:(20/10)*1rem;
	width:(26/20)*1em;
}
