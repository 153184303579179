.roadmap_list {
	@extend %global_flex_block_row_wrap_flex-start;

	position: relative;
	margin-right: -2.4em;

	&:after {
		content: '';

		position: absolute;
		top: 0;
		left: 50%;

		width: 200vw;
		height: 1px;
		margin-left: -100vw;

		background-color: $black;
		opacity: .25;

		@include bp($point_4 - 1) {
			left: auto;
			right: calc(100% - .8em);
			top: -2em;

			height: calc(100% + 4em);
			
			background-color: transparent;
			border: 1px solid $black;
		}
	}
}

.roadmap_item {
	position: relative;
	
	width: 25%;
	padding-top: 3.4em;
	padding-right: 2.4em;

	&:after {
		content: '';

		position: absolute;
		top: 0;
		left: 0;

		width: 1.6em;
		height: 1.6em;
		margin-top: -.8em;

		background-color: $red;
		border-radius: 50%;

		z-index: 2;

		@include bp($point_4 - 1) {
			margin-top: .4rem;
		}
	}

	@include bp($point_4 - 1) {
		width: 100%;
		padding: 0 0 0 3em;

		& + & {
			margin-top: 3em;
		}
	}
}

.roadmap_year {
	margin-bottom: (1.6em/2.2);

	font-size: 2.2em;
	font-weight: 600;
	color: $gray;
}

.roadmap_text {
	font-size: 1.4em;
	line-height: 1.4;
	color: $gray;
}

// title decor

.title_decor {
	@extend %global_flex_block_row_nowrap_flex-start;

	position: absolute;
	top: 10em;
	left: -.6em;

	@include bp($point_4 - 1) {
		top: 6em;
	}
}

.title_decor_item {
	width: 1.2rem;
	height: 1.2rem;

	border-radius: 50%;
	background-color: $red;

	& + & {
		margin-left: 1rem;
	}

	&:nth-child(1) {
		background-color: $black;
		opacity: .25;
	}

	&:nth-child(2) {
		background-color: $black;
	}

	&:nth-child(3) {
		background-color: $blue;
	}

}