@mixin st_btn() {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	white-space: nowrap;
	cursor: pointer;

	&:hover, &:active, &:focus {
		text-decoration: none;
	}

}

//color extends
%red_btn_1 {
	font-weight: 700;
	color: $white;
	background-color: $red;
	transition: .3s ease;

	@include st_btn();

	&:hover, &:active, &:focus {
		background: darken($red, 10%);
	}

}

%gray_btn_1 {
	font-weight: 700;
	color: $gray_5;
	background-color: $gray_4;
	transition: .3s ease;

	@include st_btn();

	&:hover, &:active, &:focus {
		background: darken($gray_4, 10%);
	}

}

%blue_btn_1 {
	font-weight: 700;
	color: $white;
	background-color: $blue;
	transition: .3s ease;

	@include st_btn();

	&:hover, &:active, &:focus {
		background: darken($red, 10%);
	}

}

//size extends
%btn_size_1 {
	min-width: 25rem;
	height: 7.7rem;
	padding: 0 1.2rem;

	line-height: 7.7rem;
	font-size: 1.8rem;

}

%btn_size_2 {
	width: 16.4rem;
	height: 5rem;
	padding: 0 1.2rem;

	line-height: 5rem;
	font-size: 1.6rem;
}