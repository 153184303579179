.advisors_block {
	@extend %global_flex_block_row_nowrap_flex-start;

	margin-bottom: 9.6em;

	@include bp($point_4 - 1) {
		flex-wrap: wrap;
	}
}

.advisors_img_wrap {
	width: 55.6%;

	@include bp($point_3) {
		width: 50%;
	}

	@include bp($point_4 - 1) {
		width: 100%;
	}
}

.advisors_img {
	display: block;
	width: 100%;
}

.advisors_text {
	width: 44.4%;
	max-width: 38.6rem;
	padding-left: (6.6em/1.6);

	font-size: 1.6em;
	line-height: 1.4;
	color: $gray_3;

	p {
		margin-bottom: (2em/1.6);

		&:last-child {
			margin-bottom: 0;
		}
	}

	@include bp($point_3) {
		width: 50%;
		max-width: 100%;
		padding-left: 2.4em;
	}

	@include bp($point_4 - 1) {
		width: 100%;
		padding-left: 0;
		margin-top: 2em;
	}
}

.advisors_list {
	@extend %global_flex_block_row_nowrap_flex-start;
	margin: 0 -4.8em;

	@include bp($point_3) {
		margin: 0;
		justify-content: center;
		flex-wrap: wrap;
	}
}

.advisor_item {
	position: relative;

	width: 37em;
	max-width: 33.33%;
	padding: 6.4em 4.8em;
	margin-right: auto;
	
	&:last-child {
		margin-right: 0;
	}

	&.bg_mod {
		background-color: $white;
		box-shadow: 0 0 5em rgba(0, 0, 0, .15);
	}

	@include bp($point_3) {
		width: 100%;
		max-width: 100%;
		text-align: center;
	}
}

.advisor_decor {
	position: absolute;
	top: 1em;
	left: -1.6em;

	width: 15.4em;
	height: 16.4em;

	background: url('../i/advisor_decor.png') repeat top left; 
	background-size: 20px 20px;
	opacity: .05;
}

.advisor_title {
	height: (9.6em/4);
	margin-bottom: (7.2em/4);

	font-size: 4em;
	line-height: 1.2;
	font-weight: 900;
	font-family: $circular;

	overflow: hidden;

	@include bp($point_3) {
		height: auto;
		margin-bottom: 1em;

		br {
			display: none;
		}
	}
}

.advisor_mail {
	margin-bottom: (7em/2);

	font-size: 2em;
	color: #25313e;

	@include bp($point_3) {
		margin-bottom: 2em;
	}
}
