.quote_block_w {
	@extend %global_flex_block_row_wrap_flex-start_flex-end;

	width: 100%;

	@include bp($point_1 + 1, min) {
		min-height: 53.4em;
	}

	@include bp($point_1) {
		padding: 10em 4em 0 0;
		justify-content: flex-end;
	}
}

.quote_block {
	@extend %global_flex_block_row_wrap_center_center;

	position: relative;

	width: 100%;
	min-height: 26.6em;
	padding: 3em;

	box-shadow: 0 0 5em rgba($black, .15);
	background-color: $white;

	@include bp($point_4, min) {
		max-width: 49.8em;
	}

	&:before {
		z-index: -1;

		position: absolute;
		top: -4.8em;
		left: 4.2em;

		content: '';

		width: 100%;
		height: 92%;


		background-color: $blue;
	}

	&:after {
		position: absolute;
		top: -7.6em;
		right: 2.8em;

		content: '';

		width: 4.2em;
		height: 4.2em;

		background-image: url("../i/decors/sml_decor.svg");
		background-position: 50% 50%;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.quote_block_in {
	width: 100%;
	max-width: (37em/2.5);

	color: $gray;
	font-weight: 700;
	font-size: 2.5em;

	p {
		&:before, &:after {
			content: '“';
		}
	}

}