@include font-face('CircularStd-Medium', 'Circular', $font_path, 500);
@include font-face('CircularStd-Black', 'Circular', $font_path, 900);

@include font-face('AvenirNext-Regular', 'Avenir', $font_path, 400);
@include font-face('AvenirNext-Italic', 'Avenir', $font_path, 400, italic);
@include font-face('AvenirNext-Medium', 'Avenir', $font_path, 500);
@include font-face('AvenirNext-DemiBold', 'Avenir', $font_path, 600);
@include font-face('AvenirNext-DemiBoldItalic', 'Avenir', $font_path, 600, italic);
@include font-face('AvenirNext-Bold', 'Avenir', $font_path, 700);

