.why_slider_wrap {
	position: relative;

	margin-left: -$gl_indent;
	margin-right: -$gl_indent;
	padding: 0 $gl_indent;

	counter-reset: why;  

	background-color: $blue;
}

.why_slider  {
	// background-color: $blue;
}


.why_item {
	height: inherit !important;
	padding: 8.3em 0 6.6em;

	@include bp(860px) {
		padding: 4em 0;
	}
}

.why_col {

	&.img_mod {
		position: relative;
		width: 43.8%;

		@include bp($point_4 - 1) {
			display: none;
		}
	}

	&.content_mod {
		width: 56.2%;
		padding-left: 7.8rem;

		@include bp($point_3) {
			padding-left: 3em;
		}

		@include bp($point_4 - 1) {
			width: 100%;
			padding-left: 0;
		}
	}
}

.why_img_wrap {
	position: absolute;

	top: 50%;
	
	width: calc(100% + 4rem);
	margin: -2rem;
	
	border-radius: 16.8rem;
	border: 2rem solid $white;
	
	transform: translateY(-54%);
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		top: -2rem;
		bottom: -2rem;
		left: -2rem;
		right: -2rem;

		background-color: $white;
		border-radius: 16.8rem;

		z-index: 1;

		@include bp($point_3) {
			border-radius: 16vw;
		}
	}

	&:after {
		content: '';
		display: block;
		padding-bottom: 125%;
	}
	
	@include bp($point_1) {
		width: 100%;
		margin: 0;
	}
	
	@include bp($point_3) {
		border-radius: 16vw;
		transform: translateY(-50%);
	}
}

.why_img {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;

	z-index: 2;
}

.why_text {
	position: relative;
	padding-left: (9.6em/1.8);
	margin-bottom: (3.4em/1.8);
	
	font-size: 1.8em;
	font-weight: 500;
	color: $gray;
	
	&:before {
		counter-increment: why;
		content: counter(why);

		position: absolute;
		top: 0;
		left: 0;

		font-size: 11.6rem;
		line-height: .8;
		font-family: $circular;
		font-weight: 700;
		color: $white;
	}
}


.why_control {
	@extend %transition_color;

	position: absolute;
	top: 50%;
	margin-top: -.8rem;

	display: block;
	width: 3em;
	height: 1.6em;

	color: $white;

	cursor: pointer;

	z-index: 10;

	&.prev_mod {
		left: 2rem;
	}

	&.next_mod {
		right: 2rem;
	}

	&.slick-disabled {
		opacity: 0;
		pointer-events: none;
	}

	&:hover, &:focus {
		text-decoration: none;
		color: $red;
	}

	@include bp(1300px) {
		top: auto;
		bottom: 5rem;

		&.prev_mod {
			left: auto;
			right: 12rem;
		}
	
		&.next_mod {
			right: 2rem;
		}
	}

	@include bp($point_3) {
		bottom: 3rem;
	}

}