//------------------------------------------------------------base styles
html {
	height: 100%;
	font: 400 calc(100vw / 1920 * 10)/1.33 $font_1;

	@media (min-width: $point_7) and (max-width: $point_1 + 400) {
		font-size: 10px;
	}

	@include bp($point_7 - 1) {
		font-size: 9px;
	}
}

body {
	position: relative;

	display: flex;
	height: 100%;

	font-size: $font_size_base;
	color: $color_text_base;

	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: $body_bg;

	@supports (display: grid) {
		display: block;
	}

	&.menu_open {
		overflow: hidden;
	}

}

.wrapper {
	position: relative;

	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: auto !important;

	overflow-x: hidden;
}

.base {
	width: 100%;
	position: relative;
	padding: $height_header 0 0;

	flex: 1 0 auto;

	body.index_mod & {
		padding-top: 0;
	}
}

a {
	text-decoration: none;
	color: $color_link_base;

	&:hover,
	&:focus {
		text-decoration: underline;
	}

}

.elements_list {
	padding: 3rem;
}

//------------------------------------------------------------base styles###
