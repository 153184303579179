.footer {
	@extend %global_flex_block_column_nowrap_flex-start_stretch;

	width: 100%;
	min-height: $height_footer;
	padding: 7em $gl_indent 5.4em;
	flex: 0 0 auto;

	font-size: 1rem;

	background-color: $black_2;
	will-change: transform;
}

.footer_top {
	@extend %global_flex_block_row_wrap_flex-start;
	margin-bottom: 4em;

	@include bp($point_3) {
		flex-direction: column;
	}
}

.footer_bottom {
	@extend %global_flex_block_row_wrap_flex-start_center;
	margin-top: auto;

	@include bp($point_5) {
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
	}
}

.footer_logo {
	display: block;
	width: 18.8em;
	height: 2.8em;
	margin-right: auto;

	@include bp($point_3) {
		margin-bottom: 4em;
		margin-left: 0;
	}
}

.logo_img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.footer_form_block {
	width: 53.6em;
	max-width: 100%;
	margin: 0 auto;

	&.index_mod {
		margin-right: 0;

		@include bp($point_3) {
			margin-bottom: 0;
		}
	}

	@include bp($point_3) {
		width: 100%;
		margin-bottom: 4em;
	}
}

.footer_form_title {
	margin-bottom: (3em/1.8);

	font-size: 1.8em;
	color: $white;

	@include bp($point_3) {
		margin-bottom: 1em;
	}
}

.footer_form {
	@extend %global_flex_block_row_nowrap_flex-start;
}

.footer_form_controller {
	position: relative;
	flex-grow: 1;
}

.form_icon {
	position: absolute;
	top: 50%;
	left: 1.4em;

	width: 2.6em;
	height: 2em;
	margin-top: -1em;

	z-index: 2;
}

// social 

.footer_social {
	&.index_mod {
		margin-left: auto;

		@include bp($point_5) {
			margin-left: 0;
			margin-bottom: 2em;
		}
	}
}

.social_list {
	@extend %global_flex_block_row_nowrap_flex-start_center;
}

.social_item {
	margin-right: 2em;

	&:last-child {
		margin-right: 0;
	}
}

.social_link {
	transition: color .3s;

	&.footer_mod {
		display: block;
		height: 1.8em;
		width: 1.8em;
		color: $white;

		&:hover, &:focus {
			color: $red;
		}
	}
}

.footer_copyright {
	@include bp($point_5) {
		order: 2;
	}
}

.footer_copyright_top {
	margin-bottom: (.4em/1.2);

	font-size: 1.2em;
	color: $white;
}

.footer_copyright_bottom {
	font-size: 1.2em;
	font-weight: 600;
	color: $white;
	text-transform: uppercase;
}